import { useCallback, useEffect, useState } from 'react';
import Stack, {
  onEntryChange,
} from '@shared/contentstack/live-preview/live-preview';
import { CMSNavPanel } from '@src/cms-components/header/components/nav-panel/nav-panel';
import { MenuSectionLPContainer } from '@shared/contentstack/live-preview/live-preview-style-overrides.styles';

const MenuSectionPreviewPage = (props: any) => {
  const { pageContext } = props;

  const [livePreviewData, setLivePreviewData] = useState<any>({});

  Stack.startLivePreview(true);

  const fetchData = useCallback(async () => {
    const response = (await Stack.getEntry({
      contentTypeUid: 'Menu_Section',
      url:
        pageContext?.url && pageContext?.url !== '/'
          ? pageContext?.url
          : '/live-preview',
    })) as any;
    const result = response[0][0];

    setLivePreviewData(result);
  }, [pageContext]);

  useEffect(() => {
    onEntryChange(() => fetchData());
  }, [fetchData]);

  return (
    !!livePreviewData && (
      <MenuSectionLPContainer>
        <CMSNavPanel state="open" menu_dropdown={livePreviewData} />
      </MenuSectionLPContainer>
    )
  );
};

export default MenuSectionPreviewPage;
